import type {EventsMaps} from '../@types/Events'

interface EventDispatcher {
  dispatchEvent(event: EventsMaps[keyof EventsMaps]): void

  addEventListener<K extends keyof EventsMaps>(type: K, callback: (event: EventsMaps[K]) => void): void

  removeEventListener<K extends keyof EventsMaps>(type: K, callback: (event: EventsMaps[K]) => void): void
}

// @ts-ignore
const eventDispatcher: EventDispatcher = new EventTarget()

export default eventDispatcher
