import type {HydraViolation} from '../@types/hydra/HydraViolation'

export class CustomError extends Error {}

export class SilentError extends Error {}

export class NetworkError extends Error {
  constructor() {
    super('error.network')
  }
}

export class AbortError extends Error {
  constructor() {
    super('error.aborted')
  }
}

export class TimeoutError extends Error {
  constructor() {
    super('error.timeout')
  }
}

export class ApiError extends Error {
  public readonly description: string
  public readonly statusCode: number

  constructor(message: string, statusCode: number, description: string) {
    super(message)
    this.description = description
    this.statusCode = statusCode
  }
}

export class ViolationsError extends ApiError {
  public readonly violations: HydraViolation[]

  constructor(message: string, statusCode: number, description: string, violations: HydraViolation[]) {
    super(message, statusCode, description)
    this.violations = violations
  }
}
