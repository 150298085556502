import {useMemo} from 'react'
import useSWR, {type Key, type SWRResponse} from 'swr'
import type {StrictTupleKey} from 'swr/_internal'
import type {Iri} from '../@types/Api'
import type {CollectionMap, HydraCollection} from '../@types/hydra/HydraCollection'
import type {HydraItem} from '../@types/hydra/HydraItem'
import type {HydraResource} from '../@types/hydra/HydraResource'
import type {FetchOption} from '../components/EnhancedTable/types'
import {ApiError} from '../utils/ApiError'
import {createCollectionIri, iriToUuid} from '../utils/iri'
import {mapItems} from '../utils/map'
import {useAuthenticatedFetcher} from './useAuth'
import {useEffectEvent} from './useEffectEvent'
import {useMutateResource, useMutateTagsFallback} from './useMutate'

type CollectionHook<T> = {
  readonly items?: HydraCollection<T>
  readonly error?: Error
}

type Filter = {
  readonly iri?: Iri
  readonly iris?: Iri[]
}

export function useItems<T>(resource: string, filter?: Filter, fetchOptions?: FetchOption): CollectionHook<T> {
  const querystring = new URLSearchParams()
  let empty = true
  if (filter?.iri) {
    querystring.set('id', iriToUuid(filter.iri))
    empty = false
  }
  if (filter?.iris) {
    for (const [i, iri] of filter.iris.entries()) {
      querystring.set(`id[${i}]`, iriToUuid(iri))
      empty = false
    }
  }
  if (fetchOptions?.page !== undefined) {
    querystring.set('page', `${fetchOptions.page + 1}`)
    querystring.set('pagination', 'true')
  }
  if (fetchOptions?.sort) {
    querystring.set(`order[${fetchOptions.sort.field}]`, fetchOptions.sort.order)
  } else {
    querystring.set('pagination', 'false')
  }
  const {data, error} = useSWR(!empty ? `${createCollectionIri(resource)}?${querystring.toString()}` : null)

  return {
    items: data,
    error,
  }
}

type CollectionFilter = Record<string, undefined | boolean | string | string[]>
export type FilterOption<T extends CollectionFilter> = Record<keyof T, string>

export function filterToQueryString<T extends CollectionFilter>(
  options: FilterOption<T>,
  filter: T | undefined,
  fetchOptions: FetchOption | undefined,
): {
  empty: boolean
  querystring: URLSearchParams
} {
  const querystring = new URLSearchParams()
  let empty = true
  if (!filter) {
    return {
      empty: true,
      querystring,
    }
  }

  const filterKeys = new Set(Object.keys(filter))
  if (filterKeys.size === 0) {
    return {
      empty: true,
      querystring,
    }
  }
  for (const [filterKey, queryParam] of Object.entries(options)) {
    if (!filterKeys.has(filterKey)) {
      continue
    }
    const filterValue: undefined | boolean | string | string[] = filter[filterKey]
    if (filterValue === undefined) {
      return {
        empty: true,
        querystring,
      }
    }
    empty = false

    if (Array.isArray(filterValue)) {
      if (filterValue.length === 0) {
        return {
          empty: true,
          querystring: new URLSearchParams(),
        }
      }
      const filterValueUnique = [...new Set(filterValue)].toSorted((a, b) => a.localeCompare(b))
      for (const [i, value] of filterValueUnique.entries()) {
        querystring.set(`${queryParam}[${i}]`, value)
      }
    } else if (typeof filterValue === 'boolean') {
      querystring.set(queryParam, filterValue ? 'true' : 'false')
    } else {
      querystring.set(queryParam, filterValue)
    }
  }

  if (fetchOptions?.page !== undefined) {
    querystring.set('page', `${fetchOptions.page + 1}`)
    querystring.set('pagination', 'true')
  }
  if (fetchOptions?.sort) {
    querystring.set(`order[${fetchOptions.sort.field}]`, fetchOptions.sort.order)
  }

  return {
    empty,
    querystring,
  }
}

export function useGet<Data = any, Error = any, SWRKey extends Key = StrictTupleKey>(key: SWRKey): SWRResponse<Data, Error | ApiError> {
  const is404 = typeof key === 'string' && key.endsWith('/00000000-0000-0000-0000-000000000000')
  const response = useSWR<Data, Error, SWRKey | undefined>(is404 ? undefined : key)
  const {data, error, mutate} = response
  if (data && error && error instanceof ApiError && error.statusCode === 404) {
    setTimeout(() => mutate(undefined), 10)
  }

  if (is404) {
    return {
      ...response,
      data: undefined,
      error: new ApiError('item not found', 404, 'item not found'),
    }
  }

  return response
}

export function useMap<T extends HydraResource>(collection: HydraCollection<T> | undefined): CollectionMap<T> {
  return useMemo<CollectionMap<T>>(() => {
    return mapItems<T>(collection?.['hydra:member'])
  }, [collection])
}

export function usePatch<T extends HydraItem>(forceInvalidation = true): (item: HydraResource, payload: object) => Promise<T> {
  const authenticatedFetcher = useAuthenticatedFetcher()
  const mutateResource = useMutateResource()
  const mutateTags = useMutateTagsFallback()

  return useEffectEvent(async (item: HydraResource, payload: object) => {
    return await mutateTags(async (mutate) => {
      const response = await authenticatedFetcher(item['@id'], {
        method: 'PATCH',
        body: JSON.stringify(payload),
      })
      await mutateResource(item['@id'], response)
      if (forceInvalidation) {
        await mutate(response['@context'])
      }

      return response
    })
  })
}

export function usePost<T extends HydraItem>(resource: string, forceInvalidation = true): (payload: object) => Promise<T> {
  const authenticatedFetcher = useAuthenticatedFetcher()
  const mutateResource = useMutateResource()
  const mutateTags = useMutateTagsFallback()

  return useEffectEvent(async (payload: object) => {
    return await mutateTags(async (mutate) => {
      const response = await authenticatedFetcher(createCollectionIri(resource), {
        method: 'POST',
        body: JSON.stringify(payload),
      })
      await mutateResource(response['@id'], response)
      if (forceInvalidation) {
        await mutate(response['@context'])
      }

      return response
    })
  })
}

export function useDelete(forceInvalidation = true): (item: HydraResource) => Promise<void> {
  const authenticatedFetcher = useAuthenticatedFetcher()
  const mutateResource = useMutateResource()
  const mutateTags = useMutateTagsFallback()

  return useEffectEvent(async (item: HydraResource) => {
    return await mutateTags(async (mutate) => {
      await authenticatedFetcher(item['@id'], {
        method: 'DELETE',
      })
      await mutateResource(item['@id'])
      if (forceInvalidation) {
        // @ts-ignore
        if (item['@context']) {
          // @ts-ignore
          await mutate(item['@context'])
        } else {
          await mutate(`/contexts/${item['@type']}`)
        }
      }
    })
  })
}

export function useUpsert<T extends HydraItem, TU extends HydraResource, UC extends object, UU extends object>(
  itemCreate: (payload: UC) => Promise<T>,
  itemUpdate: (item: TU, payload: UU) => Promise<T>,
): (item: TU | undefined, payload: UU, createExtraPayload?: Omit<UC, keyof UU>) => Promise<T> {
  return useEffectEvent(async (item: TU | undefined, payload: UU, createExtraPayload?: Omit<UC, keyof UU>): Promise<T> => {
    if (item) {
      return itemUpdate(item, payload)
    }

    // @ts-ignore
    const createPayload: UC = {...createExtraPayload, ...payload}
    return itemCreate(createPayload)
  })
}
