import {type SnackbarKey, type SnackbarMessage, type VariantType, useSnackbar} from 'notistack'
import {lazy} from 'react'
import {useEffectEvent} from './useEffectEvent'

const NotificationAction = lazy(async () => ({default: (await import('../components/Lazy/NotificationAction')).NotificationAction}))

interface Hook {
  readonly notify: (message: SnackbarMessage, variant: VariantType, preventDuplicate: boolean) => void
  readonly notifySuccess: (message: SnackbarMessage) => void
  readonly notifyError: (message: SnackbarMessage) => void
  readonly notifyInfo: (message: SnackbarMessage) => void
}

export function useNotification(): Hook {
  const {enqueueSnackbar, closeSnackbar} = useSnackbar()

  const notify = useEffectEvent((message: SnackbarMessage, variant: VariantType, preventDuplicate = false): void => {
    enqueueSnackbar(message, {
      variant,
      preventDuplicate,
      action: (snackbarId: SnackbarKey) => <NotificationAction closeSnackbar={closeSnackbar} snackbarId={snackbarId} />,
    })
  })
  const notifySuccess = useEffectEvent((message: SnackbarMessage): void => {
    notify(message, 'success', true)
  })
  const notifyError = useEffectEvent((message: SnackbarMessage): void => {
    notify(message, 'error', true)
  })
  const notifyInfo = useEffectEvent((message: SnackbarMessage): void => {
    notify(message, 'info', true)
  })

  return {
    notify,
    notifyInfo,
    notifyError,
    notifySuccess,
  }
}
