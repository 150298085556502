import {memo, useEffect, useRef, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {useStorageState} from 'react-use-storage-state'
import {userDisconnectedEventName} from '../../../../enums/event'
import {useLogin, useLogout, useRefresh} from '../../../../hooks/useAuth'
import {useNotification} from '../../../../hooks/useNotification'
import {useToken} from '../../../../states/auth'
import {UserDisconnectedEvent} from '../../../../utils/event'
import eventDispatcher from '../../../../utils/eventDispatcher'

const storageKey = 'auth_token'

const AuthNotification = memo(() => {
  const {notifyInfo} = useNotification()
  const {t} = useTranslation()

  useEffect(() => {
    const handleUserDisconnected = () => {
      notifyInfo(t('error.http.logout'))
    }

    eventDispatcher.addEventListener(userDisconnectedEventName, handleUserDisconnected)
    return () => {
      eventDispatcher.removeEventListener(userDisconnectedEventName, handleUserDisconnected)
    }
  }, [notifyInfo, t])

  return null
})

const AuthListenStorage = memo(() => {
  const [storedToken, setStoredToken] = useStorageState<string | null>(storageKey, null)
  const token = useToken()
  const login = useLogin()
  const logout = useLogout()
  const initialized = useRef(false)
  const lastStoredToken = useRef<string | null>(storedToken)
  const lastToken = useRef<string | null | undefined>(token)
  const [desiredToken, setDesiredToken] = useState<string | null | undefined>(storedToken ?? token ?? undefined)

  if (!initialized.current) {
    initialized.current = true
    if (storedToken) {
      login(storedToken)
    } else {
      logout()
    }
  }

  useEffect(() => {
    if (lastStoredToken.current !== storedToken) {
      if (lastStoredToken.current && !storedToken) {
        eventDispatcher.dispatchEvent(new UserDisconnectedEvent())
      }

      setDesiredToken(storedToken)
      lastStoredToken.current = storedToken
    } else if (lastToken.current !== token) {
      setDesiredToken(token)
      lastToken.current = token
    }
  }, [storedToken, token])

  useEffect(() => {
    setStoredToken(desiredToken ?? null)
    if (desiredToken) {
      login(desiredToken)
    } else {
      logout()
    }
  }, [desiredToken, login, logout, setStoredToken])

  return null
})

const AuthRefresh = memo(() => {
  const refresh = useRefresh()

  useEffect(() => {
    const timerTo = setTimeout(refresh, 5_000)
    const timerIn = setInterval(refresh, 3600_000)

    return () => {
      clearInterval(timerIn)
      clearTimeout(timerTo)
    }
  }, [refresh])

  return null
})

export const AuthListener = memo(() => {
  return <>
    <AuthListenStorage/>
    <AuthRefresh/>
    <AuthNotification/>
  </>
})
