import i18n from 'i18next'
import type {FetchOptions} from '../@types/fetch'
import {API_ENDPOINT, APP_VERSION} from '../config'
import {AbortError, ApiError, NetworkError, TimeoutError, ViolationsError} from './ApiError'

const MIME_TYPE = 'application/ld+json'
const MIME_TYPE_PATH = 'application/merge-patch+json'

const multiSignals = (signals: AbortSignal[]) => {
  const controller = new AbortController()

  function onAbort() {
    controller.abort()

    // Cleanup
    for (const signal of signals) {
      signal.removeEventListener('abort', onAbort)
    }
  }

  for (const signal of signals) {
    if (signal.aborted) {
      onAbort()
      break
    }
    signal.addEventListener('abort', onAbort)
  }

  return controller.signal
}

export async function fetchApi(resource: string, init?: FetchOptions): Promise<Response> {
  const initParameters: FetchOptions = {
    ...init,
    headers: {
      Accept: MIME_TYPE,
      'Accept-Language': i18n.resolvedLanguage ?? 'en',
      'X-Topo-Version': APP_VERSION,
      ...init?.headers,
    },
  }

  const controller = new AbortController()
  let timeout = false
  const timer = setTimeout(() => {
    timeout = true
    controller.abort()
  }, 20_000)
  if (initParameters.signal) {
    initParameters.signal = multiSignals([initParameters.signal, controller.signal])
  } else {
    initParameters.signal = controller.signal
  }

  try {
    return await fetch(`${API_ENDPOINT}${resource}`, initParameters)
  } catch (e) {
    if (timeout) {
      throw new TimeoutError()
    }

    if (e instanceof DOMException && e.name === 'AbortError') {
      throw new AbortError()
    }

    if (e instanceof TypeError) {
      throw new NetworkError()
    }

    throw e
  } finally {
    clearTimeout(timer)
  }
}

export async function fetchApiResource(resource: string, init?: FetchOptions): Promise<any> {
  if (init) {
    if (init.body !== undefined && !(init.body instanceof FormData) && !init.headers?.['Content-Type']) {
      if (init.method === 'PATCH') {
        init.headers = {...init.headers, 'Content-Type': MIME_TYPE_PATH}
      } else {
        init.headers = {...init.headers, 'Content-Type': MIME_TYPE}
      }
    }
  }

  const resp = await fetchApi(resource, init)
  if (resp.status === 204 || resp.status === 202) {
    return null
  }

  const json = await resp.json()
  if (resp.ok) {
    if (json['@context']) {
      json['@tags'] = []
      if (resp.headers.has('xkey')) {
        const xkey = resp.headers.get('xkey')
        if (xkey) {
          json['@tags'] = xkey.split(/,/)
        }
      }
      if (json['@type'] === 'hydra:Collection') {
        json['@tags'].push(json['@context'])
      }
    }

    return json
  }

  const message = json['hydra:title'] ?? json.message
  const description = json['hydra:description'] || resp.statusText
  if (!json.violations) {
    throw new ApiError(message, resp.status, description)
  }

  throw new ViolationsError(message, resp.status, description, json.violations)
}

export async function fetchApiResourceAuthenticated(token: string, resource: string, init?: FetchOptions): Promise<any> {
  return await fetchApiResource(resource, {
    ...init,
    headers: {
      ...init?.headers,
      Authorization: `Bearer ${token}`,
    },
  })
}
