import {memo, useEffect} from 'react'
import type {BeforeInstallPromptEvent} from '../../../../@types/BeforeInstallPromptEvent'
import {useSetInstallPrompt} from '../../../../states/installPrompt'

export const InstallPromptListener = memo(() => {
  const setInstallPrompt = useSetInstallPrompt()

  useEffect(() => {
    if (!window.verticaltopo?.beforeinstallprompt) {
      return
    }

    setInstallPrompt(window.verticaltopo.beforeinstallprompt)
  }, [setInstallPrompt])

  useEffect(() => {
    const installPromptHandler = (event: BeforeInstallPromptEvent) => {
      event.preventDefault()
      setInstallPrompt(event)
    }
    const installedAppHandler = () => {
      setInstallPrompt(undefined)
    }

    window.addEventListener('beforeinstallprompt', installPromptHandler)
    window.addEventListener('appinstalled', installedAppHandler)

    return () => {
      setInstallPrompt(undefined)
      window.removeEventListener('beforeinstallprompt', installPromptHandler)
      window.removeEventListener('appinstalled', installedAppHandler)
    }
  }, [setInstallPrompt])

  return null
})
