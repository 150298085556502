import {useRegisterSW} from 'virtual:pwa-register/react'
import {useSnackbar} from 'notistack'
import {lazy, memo, useEffect, useRef, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {APP_VERSION} from '../config'
import {notificationNewReleaseEventName} from '../enums/event'
import {useMeta} from '../hooks/entity/useMeta'
import {useEffectEvent} from '../hooks/useEffectEvent'
import {useIdle} from '../hooks/useIdle'
import eventDispatcher from '../utils/eventDispatcher'

const ReleaseReloadButton = lazy(async () => ({default: (await import('../components/Lazy/ReleaseReloadButton')).ReleaseReloadButton}))

const idleTimeout = 10 * 60 * 1000   // 10min force refresh the page after X minutes of inactivity
const refreshTimeout = 30 * 60 * 1000 // 30min force refresh the page even with activity
const updateInterval = 5 * 60 * 1000 // 5min update the SW every X minutes
const readyTimeout = 30 * 1000       // 30sec delay before display the notification

const sleep = (ms: number): Promise<void> => {
  return new Promise(resolve => setTimeout(resolve, ms))
}

export const ReleaseHandler = memo(() => {
  const {enqueueSnackbar} = useSnackbar()
  const {t} = useTranslation()
  const [displayPrompt, setDisplayPrompt] = useState(false)
  const {meta} = useMeta(APP_VERSION !== 'dev')
  const idle = useIdle(idleTimeout)
  const [ready, setReady] = useState(false)
  const [swRegistration, setSwRegistration] = useState<ServiceWorkerRegistration|undefined>()
  const [postponeUpdate, setPostponeUpdate] = useState(false)
  const isMountedRef = useRef(false)

  const {
    needRefresh: [needRefresh],
    updateServiceWorker,
  } = useRegisterSW({
    onRegisteredSW(scriptUrl, swRegistration) {
      if (!isMountedRef.current) {
        return
      }
      setSwRegistration(swRegistration)
    }
  })

  const updateSw = useEffectEvent(async () => {
    const swr = swRegistration
    if (!swr) {
      setPostponeUpdate(true)
      return
    }

    try {
      await swr.update()
    } catch {
    }
  })
  const refreshSw = useEffectEvent(async () => {
    try {
      if (updateServiceWorker) {
        await updateServiceWorker(true)
      }
    } finally {
      await sleep(1_000)
      window.location.reload()
    }
  })

  // maintain a reference that tell if the component is mounted
  useEffect(() => {
    isMountedRef.current = true
    return () => {
      isMountedRef.current = false
    }
  }, [])

  // update the SW if postponed (happens when something ask for update but sw was not yet ready)
  useEffect(() => {
    if (swRegistration && postponeUpdate) {
      updateSw()
    }
  }, [postponeUpdate, swRegistration, updateSw])

  // periodically update the SW
  useEffect(() => {
    const timeoutTimer = setTimeout(updateSw, 5_000)
    const timeoutInterval = setInterval(updateSw, updateInterval)

    return () => {
      clearTimeout(timeoutTimer)
      clearInterval(timeoutInterval)
    }
  }, [updateSw])

  // Mark the prompt ready to display after 30sec
  useEffect(() => {
    const timer = setTimeout(() => {
      setReady(true)
    }, readyTimeout)

    return () => {
      clearTimeout(timer)
    }
  }, [])

  // Display the prompt when need refresh
  useEffect(() => {
    if (!needRefresh) {
      return
    }
    setDisplayPrompt(needRefresh)
  }, [needRefresh])

  // force refresh when user is IDLE
  useEffect(() => {
    if (!idle || !displayPrompt) {
      return
    }

    refreshSw()
  }, [idle, displayPrompt, refreshSw])

  // force refresh 30 minutes anyway
  useEffect(() => {
    if (!displayPrompt) {
      return
    }

    const timer = setTimeout(() => {
      refreshSw()
    }, refreshTimeout)

    return () => {
      clearTimeout(timer)
    }
  }, [displayPrompt, refreshSw])

  // Trigger update when receiving mercure event
  useEffect(() => {
    const handleNotification = () => {
      updateSw()
    }

    eventDispatcher.addEventListener(notificationNewReleaseEventName, handleNotification)
    return () => {
      eventDispatcher.removeEventListener(notificationNewReleaseEventName, handleNotification)
    }
  }, [updateSw])

  // Trigger update when receiving a request with newer version
  useEffect(() => {
    if (!meta) {
      return
    }
    if (APP_VERSION === 'dev') {
      return
    }
    if (meta.clientVersion > APP_VERSION) {
      updateSw()
    }
  }, [meta, updateSw])

  // display notification for reloading the page
  useEffect(() => {
    if (!displayPrompt || !ready) {
      return
    }

    enqueueSnackbar(t('common.new_version.message'), {
      variant: 'info',
      persist: true,
      preventDuplicate: true,
      action: <ReleaseReloadButton onClick={refreshSw}/>,
    })
  }, [displayPrompt, enqueueSnackbar, ready, refreshSw, t])


  return null
})
